<template>
    <div>
        <strong>Purchased Item Upload</strong>
        <br>
        <div class="form-group">
            <label>Material</label>
            <Typeahead ref="materialFileHandlerMaterial" cdi_type="material" v-bind:ID.sync="state.referenceID" />
        </div>
        <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Material",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            this.$refs.materialFileHandlerMaterial.$data.object = this.$appStore.state.fileUploadContextData.data.raw_material;
        }
    }
</script>

<style scoped>

</style>